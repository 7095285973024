import { Copy } from '@saleswhale/barnacle/icons';
import {
  Button,
  Modal,
  NegativeToast,
  PositiveToast,
  SupportLink,
  TextArea,
} from 'app/components/Elements';
import { BotEmailProvider, BotSchema } from 'app/schemas';
import { SHORT_COMPANY_NAME } from 'config/constants';
import { capitalize } from 'lodash';
import { useHistory } from 'react-router-dom';
import { formatEmailProvider } from 'utils/formatting';
import {
  sendClickBotRestrictedModalEmailProviderLink,
  sendClickBotRestrictedModalGoToAiInbox,
  sendClickBotRestrictedModalLearnMore,
  sendClickCopyBotRestrictedErrorMessage,
} from './RestrictedBotModal.tracking';
import styles from './RestrictedBotModal.module.scss';

interface Props {
  bot: BotSchema;
  closeModal: () => void;
  isOpen: boolean;
  isShowingAiInboxRedirect: boolean;
}
export function RestrictedBotModal({ closeModal, isOpen, bot, isShowingAiInboxRedirect }: Props) {
  const history = useHistory();
  const isSendGridInbox = bot.emailProvider === BotEmailProvider.SENDGRID;
  const botEmailProvider = formatEmailProvider(bot.emailProvider);
  const errorMessage = bot.botErrors && bot.botErrors.length > 0 ? bot.botErrors[0].details : '';
  const handleCopyClick = async () => {
    sendClickCopyBotRestrictedErrorMessage(bot.id.toString());
    try {
      await navigator.clipboard.writeText(errorMessage);
      PositiveToast('Successfully copied to clipboard!');
    } catch (e) {
      NegativeToast('Unable to copy message. Please try again.');
    }
  };
  const goToAiInbox = () => {
    sendClickBotRestrictedModalGoToAiInbox(bot.id.toString());
    history.push(`/ai_inboxes/${bot.id}`);
    closeModal();
  };
  const primaryButtonRedirect = isShowingAiInboxRedirect
    ? { onPrimary: goToAiInbox, primary: 'Go to AI inbox' }
    : {};
  const t_restrictedEmailModalContent = isSendGridInbox ? (
    <>
      <div className={styles.Subheader}>What happened?</div>
      <p>
        AI inboxes connected to {botEmailProvider} are restricted due to an issue with your account.
        This might involve domain settings, API key permissions, or account status.
      </p>
      <div className={styles.Subheader}>How to fix it?</div>
      <ol className={styles.List}>
        <li>Contact your {botEmailProvider} admin to validate:</li>
        <ol className={styles.List} type="a">
          <li>Domain settings</li>
          <li>API key permissions</li>
          <li>Account status</li>
        </ol>
        <li>
          If unclear, your admin may need to contact {botEmailProvider} support for more in-depth
          diagnostics.
        </li>
        <li>Update inbox status in {SHORT_COMPANY_NAME}.</li>
      </ol>
      <div className={styles.Note}>
        <strong>Note:</strong> The exact issue may not be clear due to limited error data. A
        thorough review by your administrator may be necessary.
      </div>
      <div className={styles.Ctas}>
        <SupportLink
          openInNewTab
          href="https://support.6sense.com/knowledge-base/6sense-conversational-email-restricted-ai-inbox-alerts/#reasons-for-restrictions"
          label="Learn more"
          onClick={() => sendClickBotRestrictedModalLearnMore(bot.id.toString())}
        />
      </div>
    </>
  ) : (
    <>
      <div className={styles.Subheader}>What happened?</div>
      <p>
        {capitalize(botEmailProvider)} restricted your AI inbox due to high email volume, missing
        email authentication records, or spam-like keywords.
      </p>
      <div className={styles.Subheader}>How to fix it?</div>
      <ol className={styles.List}>
        <li>
          <strong>Contact email admin:</strong> Contact your email administrator to remove the AI
          inbox from restricted entities. Refer to the{' '}
          <SupportLink
            noIcon
            openInNewTab
            href="https://learn.microsoft.com/en-us/defender-office-365/outbound-spam-restore-restricted-users"
            label="Email Provider Documentation"
            onClick={() => sendClickBotRestrictedModalEmailProviderLink(bot.id.toString())}
          />{' '}
          for more details.
        </li>
        <li>
          <strong>Update status:</strong> Once resolved, update your inbox status in your{' '}
          <SupportLink
            noIcon
            href={`/ai_inboxes/${bot.id}`}
            label="AI inbox details"
            openInNewTab={false}
            testId="RestrictedBotModal__inboxButton"
          />{' '}
          page.
        </li>
        <li>
          <strong>Unpause campaigns:</strong> Decide which campaigns to resume.
        </li>
        <li>
          <strong>Review campaigns:</strong> Check your campaign emails for spam-like keywords.
        </li>
        <li>
          <strong>Confirm authentication:</strong> Make sure your DKIM, SPF, and DMARC records are
          set up correctly.
        </li>
      </ol>
      <div className={styles.Ctas}>
        <SupportLink
          openInNewTab
          href="https://support.6sense.com/knowledge-base/6sense-conversational-email-restricted-ai-inbox-alerts/#reasons-for-restrictions"
          label="Learn more"
          onClick={() => sendClickBotRestrictedModalLearnMore(bot.id.toString())}
        />
        <SupportLink
          icon={<Copy size={16} />}
          label="Copy error message"
          onClick={handleCopyClick}
          testId="RestrictedBotModal__copy"
        />
      </div>
      <div className={styles.TextAreaContainer}>
        <Button
          className={styles.TextAreaCopyButton}
          icon={<Copy />}
          label="Copy error message"
          onClick={handleCopyClick}
          size="s"
          testId="RestrictedBotModal__copyButton"
          theme="secondary"
        />
        <TextArea
          readOnly
          className={styles.TextArea}
          name="restrictedErrorMessage"
          rows={6}
          testId="RestrictedBotModal__errorMessage"
          value={errorMessage}
        />
      </div>
    </>
  );

  if (!bot) return null;
  return (
    <Modal
      isCancellable
      cancel="Close"
      className={styles.Modal}
      isOpen={isOpen}
      onClose={closeModal}
      size="large"
      testId="RestrictedBotModal"
      title="View details"
      {...primaryButtonRedirect}
    >
      {t_restrictedEmailModalContent}
    </Modal>
  );
}
